<template>
    <section>
        <div class="mb-5">
            <Navbar/>
        </div>
        <Spinner :color="'text-light'" v-if="isLoading" />
        <div class="calltoaction px-5">          
            <section class="row" v-if="!isLoading">
                <div class="col-md-9 text-center">
                    <div class="card my-5">
                        <div class="card-header">
                            <h2>Critique (Anti-thesis)</h2>
                        </div>
                        <div class="card-body">
                            <p  class="blog-post-meta pb-4">
                                {{critique.created_at && critique.created_at | moment}} by 
                                {{critique.created_by && critique.created_by.first_name}} 
                                    {{critique.created_by && critique.created_by.last_name}}
                                </p>
                                <img v-if="critique.image" class="img-thumbnail" :src="critique.image" alt="">
                            
                             <div class="d-flex px-3 text-center">
                                {{critique.summary}}
                            </div>
                             <div class="d-flex">
                                 <a  class="p-2" v-if="critique.video" :href="`${critique.video}`" target="_blank" >Youtube</a>
                                <a class="p-2"  v-if="critique.pdf" :href="`${url()}${critique.pdf}`" target="_blank" download>pdf</a>
                                <a  class="p-2" v-if="critique.doc" :href="`${url()}${critique.doc}`" target="_blank" download>document</a>
                                <a  class="p-2" v-if="critique.pic" :href="`${url()}${critique.pic}`" target="_blank" download>image</a>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button v-if="critique.enterpreneurs_plan.length == 0"  @click="addResolution(critique.leaders_vision_id,critique.id)">ADD RESOLUTION</button>
                        </div>
                    </div>
                        
                </div>
                <div class="col-md-3">
                    <DebateSidebar/>
                </div>
            </section> 
        </div>
        <footer/>
    </section>
</template>

<script>
/* allow-no-unused-vars */
import moment from 'moment'
import Navbar from '../appBar'
import DebateSidebar from '../debateSidebar'
import authors from '../../helpers/debates'
import Spinner from '@/components/Spinner'
import url from '../../helpers/url'
import Footer from '../New/Footer.vue'

    export default {
        name:'debatecritique',
        components:{
            Navbar,
            Spinner,
            DebateSidebar,
                Footer
        },
        
    filters: {
            moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
            },
        data() {
            return {
                critique: {},
                isLoading:false,
            }
        },
        methods:{
            url() {
                return url()
            }, 
            addResolution(challengeID,critiqueID) {
                const user = this.$session.get('entity')
                if(!user) {
                    window.location.replace(`/redirect?service=login`)
                }
                this.$router.replace(`/challenge/${challengeID}/critique/${critiqueID}/add_plan`)
            },
            fetchChallengeDetails(){
                this.isLoading = true
                this.$store.dispatch('fetchLeadersVision')
                .then(response =>{
                    const challenge = response.data.find(challenge => challenge.id == this.$route.params.challengeID)
                    this.critique = challenge.thinkers_critique[0]
                     if(!challenge) {
                         this.$router.replace({name:'pageNotfound'})
                     }
                  
                     this.isLoading = false
                    
                })
                .catch(err => {
                    this.isLoading = false
                    //     if(err?.response?.data == undefined) {
                    //     this.toast({
                    //         message:'Network error. Check network connection and try again.',
                    //         type:'error'
                    //     })
                    // }
                    })
            }
        },
        mounted() {
        document.querySelector('body').setAttribute('style', 'background-color:#ba2428')
        this.fetchChallengeDetails()
        
    },
    beforeDestroy() {
        document.querySelector('body').removeAttribute('style')
    },
    }
</script>

<style >
 button{
        background-color: #bb3826;
        color:#fff;
        border:1px solid #bb3826;
        padding:10px;
        transition: all 0.5s;
        outline: 0;
        font-size: .9em;
    }
    button:hover, button:active{
        background-color:#ffc106;
        color: #ad5457 ;
    }
    .bg-article{
        background: #bb3826;
    }
.calltoaction{
    padding-top: 150px;
    padding-left: 10px;
    padding-right: 10px;
}
.card-user {
  position: absolute;
  right: 10px;
  top: 10px;
}
.card-category {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
}
h2{
    text-transform: uppercase;
}
  .blog-post-meta {
    margin-bottom: 20px;
    color: #999;
    }

</style>