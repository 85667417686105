<template>
    <section>
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="row">
                    <div class="col-sm-12 p-3 mx-3">
                        <form class="form-inline">
                        <h4 class="text-white my-3 mx-2">Looking for something?</h4>
                        <input class="form-control" type="search" placeholder="Search" aria-label="Search">
                        <button class="btn btn-outline-warning mx-2 my-3" type="submit">Search</button>
                     </form>
                    </div>
                </div>
                <!-- <h3 class="text-white pl-2 pt-3">Trending</h3>
                <hr>
                <p class="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta cumque maxime itaque voluptatum iste soluta.
                     Similique, corporis et. Ipsa perferendis minima quisquam at alias laborum, officia suscipit quo? Libero, nihil.
                     Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta cumque maxime itaque voluptatum iste soluta. Similique, corporis et. Ipsa perferendis minima quisquam at alias 
                     laborum, officia suscipit quo? Libero, nihil.
                     </p>
                  <hr> -->
                  <!-- <h3 class="text-white">Years</h3>   
                  <div class="form-group">
                      <select name="" class="form-control">
                          <option value="">select year</option>
                      </select>
                  </div> -->
                  <hr>
                  <!-- <div class="row">
                      <div class="container px-3">
                          <h3 class="text-white">Archive</h3>   
                  <div class="form-group">
                      <select name="" class="form-control">
                          <option value="">select month</option>
                      </select>
                  </div>
                  <hr>
                  <h3 class="text-white" id="google_translate_element">Translate by Google</h3>   
                  <div class="form-group">
                      <select name="" class="form-control">
                          <option value="">select language</option>
                      </select>
                  </div>
                      </div>
                  </div> -->
                  <!-- <hr> -->
                  <h3 class="text-white">Subscribe</h3>   
                  <p class="text-white">Provide us with your email address so we can send you our newsletters and updates when they become available 
                      @ <a href="mailto:am@cremap.com" class="text-warning">am@cremap.com</a>
        
                  </p>
                  <!-- <p class="text-white pt-3">Join other followers</p>
                  <button class="btn btn-warning text-white">Follow</button> -->
            </div>
        </div>
    </section>
</template>
// <script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>

<script>
// import '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    export default {
        created() {
            function googleTranslateElementInit() {
            new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
            }
        }
    }
</script>

<style  scoped>
hr{
    background-color:#fff;
}
</style>