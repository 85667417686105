<template>
   <div class="container spinner" style=" padding:10em 0">
            <div class="row justify-content-center ">
                <div class="d-flex justify-content-center">
                <div :class="color "  class="spinner-grow" role="status" style="width: 3rem; height: 3rem;">
                <span class="sr-only">Loading...</span>
                </div>
                </div>
            </div>
        </div>
</template>

<script>

export default {
    props:['color']
}
</script>

<style>

</style>